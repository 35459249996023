import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText } from '@material-ui/core';

const ConfirmDialog = (props) => {
  const { title, children, open, onClose, onConfirm } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent><DialogContentText>{children}</DialogContentText></DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
            onConfirm();
          }}
          color="primary"
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
