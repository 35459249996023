import React, { useState, useEffect, useCallback } from 'react'
import { Switch } from 'react-router'
import NotificationManager from 'react-notifications/lib/NotificationManager'

import { FORM_ACTIONS } from 'src/utils/constants/general'
import Loading from 'src/components/shared/loading'
import PortadasService from 'src/services/PortadasService'
import { showDeveloperMessageConsole } from 'src/helpers/random'
import { routesApp } from 'src/routesService'
import AuthorizedRoute from 'src/components/shared/AuthorizedRoute'

import PortadasList from 'src/components/portadas/PortadasList'
import PortadaView from 'src/components/portadas/PortadaView'
import ComponentesContainer from './ComponentesContainer'

/*
export default function useResource(initialValue) {
  return React.useState({
    data: initialValue,
    pagination: {
      current_page: 1,
      per_page: 5,
      total: 0,
      next_page_url: '',
      prev_page_url: '',
      last_page: 0,
    },
    loading: false,
  })
}
 */

const PortadasContainer = ({ history, location, match: { path } }) => {
  const [errors, setErrors] = useState(null)

  /*
  const [portadasPage, setPortadasPage] = useResource(null)
  */
  const [portadasPage, setPortadasPage] = useState({
    data: null,
    pagination: {
      current_page: 1,
      per_page: 5,
      total: 0,
      next_page_url: '',
      prev_page_url: '',
      last_page: 0,
    },
    loading: false
  })

  const fetchPortadas = useCallback(
    (page = 1, rows = 10) => {
      const filters = location.search
      return PortadasService.getPortadas(page, rows, filters)
        .then((response) => response.data)
        .then(({ data, ...pagination }) => {
          setPortadasPage({
            data,
            pagination,
            loading: false
          })
        })
        .catch((error) => {
          setErrors(error.response ? error.response.data.errors : null)
          setPortadasPage((previousState) => ({ ...previousState, loading: false }))
          NotificationManager.error('Error al obtener las portadas')
          showDeveloperMessageConsole('PortadasContainer - fetchPortadas', error)
        })
    }, [location.search],
  )
  const handleSubmit = (id = null) => (
    async (submitData) => {
      try {
        if (id) {
          await PortadasService.updatePortada(submitData, id).then((response) => {
            setPortadasPage(({ data: prevPage }) => ({
              ...portadasPage,
              data: prevPage?.map(
                (user) => (user.id === response.data.response.id ? response.data.response : user)
              ),
            }))
          })

          NotificationManager.success('Portada actualizada exitosamente')
        } else {
          await PortadasService.addPortada(submitData).then((response) => {
            if (portadasPage.data.length < Number(portadasPage.pagination.per_page)) {
              const {
                pagination: {
                  total,
                  to,
                  ...pagination
                }
              } = portadasPage

              setPortadasPage(({ data: prevPage }) => ({
                ...portadasPage,
                pagination: { ...pagination, total: total + 1, to: to + 1 },
                data: [...(prevPage ?? []), response.data.response],
              }))
            } else {
              setPortadasPage({ ...portadasPage, loading: true })
            }
          })

          NotificationManager.success('Portada creada exitosamente')
        }
      } catch (error) {
        setErrors(error.response ? error.response.data.errors : null)
        NotificationManager.error('Error al guardar la portada')
        showDeveloperMessageConsole('PortadasContainer - handleSubmit: ', error)
      }

      history.push(routesApp.portadas.path, { formSubmit: true })
    }
  )

  const handleDeletePortada = async (id) => {
    NotificationManager.info('Eliminando portada...', null, 1500)
    try {
      await PortadasService.deletePortada(id)
      await fetchPortadas()
      NotificationManager.success('Portada eliminada correctamente')
    } catch (error) {
      showDeveloperMessageConsole('Error al eliminar portada: ', error)
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.')
    }
  }

  const handlePublicarPortada = async (portadaId) => {
    // setLoadingPublish(true)
    NotificationManager.info('Publicando portada...', null, 1500)
    try {
      await PortadasService.publishPortada(portadaId)
      await fetchPortadas()
      NotificationManager.success('Portada publicada correctamente')
      history.push(routesApp.portadas.path)
      // setLoadingPublish(false)
    } catch (error) {
      // setLoadingPublish(false)
      showDeveloperMessageConsole('Error al publicar portada: ', error)
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.')
    }
  }

  useEffect(() => {
    setPortadasPage((previousState) => ({ ...previousState, loading: true }))
  }, [location.search])

  useEffect(() => {
    if (location.pathname === routesApp.portadas.path && !location.state) {
      setPortadasPage((previousState) => ({ ...previousState, loading: true }))
    }
  }, [location.pathname, location.state])

  useEffect(() => {
    if (portadasPage.loading) fetchPortadas()
  }, [portadasPage.loading, fetchPortadas])

  if (portadasPage.data === null) {
    return <Loading />
  }

  const portadasRoutes = routesApp.portadas.children

  return (
    <Switch>
      <AuthorizedRoute
        exact
        path={path}
        component={() => (
          <PortadasList
            portadas={portadasPage.data}
            pagination={portadasPage.pagination}
            loading={portadasPage.loading}
            getAllPortadas={fetchPortadas}
            deletePortada={handleDeletePortada}
            publicarPortada={handlePublicarPortada}
          />
        )}
        permission={portadasRoutes.index.permission}
      />
      <AuthorizedRoute
        exact
        path={`${path}/${portadasRoutes.add.path}`}
        component={(props) => (
          <PortadaView
            action={FORM_ACTIONS.ADD}
            errors={errors}
            loading={portadasPage.loading}
            onSubmitCallback={handleSubmit}
            {...props}
          />
        )}
        permission={portadasRoutes.add.permission}
      />
      <AuthorizedRoute
        exact
        path={`${path}/${portadasRoutes.edit.path}`}
        component={(props) => (
          <PortadaView
            action={FORM_ACTIONS.EDIT}
            portadas={portadasPage.data}
            errors={errors}
            loading={portadasPage.loading}
            onSubmitCallback={handleSubmit}
            {...props}
          />
        )}
        permission={portadasRoutes.edit.permission}
      />
      <AuthorizedRoute
        exact
        path={routesApp.componentes.path}
        component={(props) => (
          <ComponentesContainer
            portadas={portadasPage.data}
            errors={errors}
            loading={portadasPage.loading}
            {...props}
          />
        )}
        permission={portadasRoutes.edit.permission}
      />
      <AuthorizedRoute
        exact
        path={routesApp.addComponente.path}
        component={(props) => (
          <ComponentesContainer
            portadas={portadasPage.data}
            errors={errors}
            loading={portadasPage.loading}
            {...props}
          />
        )}
        permission={portadasRoutes.edit.permission}
      />
      <AuthorizedRoute
        exact
        path={routesApp.editComponente.path}
        component={(props) => (
          <ComponentesContainer
            portadas={portadasPage.data}
            errors={errors}
            loading={portadasPage.loading}
            {...props}
          />
        )}
        permission={portadasRoutes.edit.permission}
      />
    </Switch>
  );
}

export default PortadasContainer
