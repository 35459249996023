import React from 'react'
import SubformItemsAgrupadorItem from './subform-items-agrupador-item';

const SubformItemsAgrupador = (props) => {

  const {
    values,
    errors,
    touched,
    swap,
    push,
    remove,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
  } = props;

  return (
    <div>
      { values.item.map((itemdata, index) => <SubformItemsAgrupadorItem
        key={index}
        index={index}
        values={values}
        errors={errors}
        touched={touched}
        swap={swap}
        remove={remove}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />) }

      {values.item.length < 30 && <button
        type='button'
        className='add-item-agrupador'
        onClick={() => push({
          text:'',
          divURL:'',
          willTargetBlank:false,
          iconURL:'',
          bgColor:'#D6D8D9',
        })}
      >
        AÑADIR ITEM
      </button>}
    </div>
  );
};

export default SubformItemsAgrupador;
