import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import {
  Button,
  createTheme,
  makeStyles,
  ThemeProvider
} from '@material-ui/core';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
}));

const BackButton = (props) => {
  const { to, disabled } = props;
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Link
        to={to}
      >
        <Button
          color="secondary"
          variant="outlined"
          disabled={disabled}
          startIcon={<BackIcon />}
          className={classes.color}
        >
          Atrás
        </Button>
      </Link>
    </ThemeProvider>
  );
};

export default BackButton;
