export const ROLES_PERMISSIONS = {
  VIEW_ROLES: 'view-roles',
  CREATE_ROLES: 'create-roles',
  EDIT_ROLES: 'edit-roles',
  DELETE_ROLES: 'delete-roles'
}

export const PERMISSIONS_PERMISSIONS = {
  VIEW_PERMISSIONS: 'view-permissions'
}

export const USERS_PERMISSIONS = {
  VIEW_USERS: 'view-users',
  CREATE_USERS: 'create-users',
  EDIT_USERS: 'edit-users',
  DELETE_USERS: 'delete-users'
}

export const AGRUPADORES_PERMISSIONS = {
  VIEW_AGRUPADOR: 'view-question_answer_groups',
  CREATE_AGRUPADOR: 'create-question_answer_groups',
  EDIT_AGRUPADOR: 'edit-question_answer_groups',
  DELETE_AGRUPADOR: 'delete-question_answer_groups'
}

export const FAQS_PERMISSIONS = {
  VIEW_FAQ: 'view-question_answers',
  CREATE_FAQ: 'create-question_answers',
  EDIT_FAQ: 'edit-question_answers',
  DELETE_FAQ: 'delete-question_answers'
}

export const PAGINAS_INSTITUCIONALES_PERMISSIONS = {
  VIEW_PAGINAS: 'view-institutional_pages',
  CREATE_PAGINAS: 'create-institutional_pages',
  EDIT_PAGINAS: 'edit-institutional_pages',
  DELETE_PAGINAS: 'delete-institutional_pages'
}

export const PANEL_USER_PERMISSIONS = {
  VIEW_PANEL_USER: 'view-panel_user',
  CREATE_PANEL_USER: 'create-panel_user',
  EDIT_PANEL_USER: 'edit-panel_user',
  DELETE_PANEL_USER: 'delete-panel_user'
}

export const MENU_PERMISSIONS = {
  VIEW_MENU: 'view-menu_options',
  CREATE_MENU: 'create-menu_options',
  EDIT_MENU: 'edit-menu_options',
  DELETE_MENU: 'delete-menu_options'
}

export const LOGOS_PERMISSIONS = {
  VIEW_LOGO: 'view-logos',
  CREATE_LOGO: 'create-logos',
  EDIT_LOGO: 'edit-logos',
  DELETE_LOGO: 'delete-logos'
}

export const CONTACT_PERMISSIONS = {
  VIEW_CONTACT: 'view-contact',
  EDIT_CONTACT: 'edit-contact',
  VIEW_MESSAGE: 'view-contact_message',
  EDIT_MESSAGE: 'edit-contact_message'
}

export const FOOTER_TEXT_PERMISSIONS = {
  VIEW_FOOTER_TEXT: 'view-text_footer',
  EDIT_FOOTER_TEXT: 'edit-text_footer'
}

export const VISITOR_REGISTER_PERMISSIONS = {
  VIEW_VISITOR_REGISTER: 'view-visitor_register',
  EDIT_VISITOR_REGISTER: 'edit-visitor_register'
}

export const CONTACT_MESSAGE_PERMISSIONS = {
  VIEW_CONTACT_MESSAGE: 'view-contact_message',
  EDIT_CONTACT_MESSAGE: 'edit-contact_message'
}

export const PORTADAS_PERMISSIONS = {
  VIEW_PORTADA: 'view-front_pages',
  CREATE_PORTADA: 'create-front_pages',
  EDIT_PORTADA: 'edit-front_pages',
  DELETE_PORTADA: 'delete-front_pages'
}

export const COMPONENTES_PERMISSIONS = {
  VIEW_COMPONENTE: 'view-page_components',
  CREATE_COMPONENTE: 'create-page_components',
  EDIT_COMPONENTE: 'edit-page_components',
  DELETE_COMPONENTE: 'delete-page_components'
}

export const CKEDITOR_PERMISSIONS = {
  VIEW_SOURCE: 'ckeditor_html_source',
}

export const NEWS_PAGE_PERMISSIONS = {
  VIEW_PAGINAS: 'view-news_pages',
  CREATE_PAGINAS: 'create-news_pages',
  EDIT_PAGINAS: 'edit-news_pages',
  DELETE_PAGINAS: 'delete-news_pages'
}

export const UPLOAD_PERMISSIONS = {
  CREATE_UPLOAD: 'create-upload',
  VIEW_UPLOAD: 'view-upload',
  EDIT_UPLOAD: 'edit-upload',
  DELETE_UPLOAD: 'delete-upload',
}
