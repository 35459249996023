/* eslint-disable array-bracket-spacing */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable brace-style */
/* eslint-disable indent */
import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import ErrorsValidation from 'src/components/shared/displayErrorsValidations';
import { useParams } from 'react-router-dom';
import UploadService from 'src/services/UploadService';
import Loading from 'src/components/shared/loading';
import { NotificationManager } from 'react-notifications';
import { ArchivoEditor } from './ArchivoEditor';
import PageContainer from '../shared/PageContainer';

export function ArchivosContainerEdit()
{
    const { uploadId } = useParams();
    const [ error, setError ] = React.useState(null);
    const [ upload, setUpload ] = React.useState(null);

    React.useEffect(() =>
    {
        UploadService.get(uploadId)
        .then((res) =>
        {
            setUpload(res.data);
        })
        .catch((err) =>
        {
            setError(err);
            NotificationManager.error('Error cargando el archivo');
        })
    }, [uploadId]);

    if (error)
    {
        return <></>
    }

    if (!upload)
    {
        return <Loading />
    }

    return (
<>
<PageContainer title="Editar archivo" maxWidth={false}>
    <Typography
        color="textPrimary"
        variant="h2"
        className="mb-3"
    >
        Editar archivo
    </Typography>
    <ErrorsValidation errors={undefined} />
    <Card>
        <CardContent>
            <ArchivoEditor archivo={upload} />
        </CardContent>
    </Card>
</PageContainer>
</>
    )
}
