/* eslint-disable brace-style */
/* eslint-disable indent */

export function readFileForUpload(file, maxChunkSize, upload)
{
    const fileSize = file.size;
    let chunkSize = 0;
    let fileOffset = 0;

    return new Promise((done, reject) =>
    {
        const readEventHandler = (evt) =>
        {
            if (evt.target.error == null)
            {
                const res = evt.target.result;

                if (res.byteLength > 0)
                {
                    upload(res, chunkSize, fileOffset, fileSize)
                    .then(() =>
                    {
                        chunkReaderBlock();
                    })
                    .catch(reject);
                }
                if (fileOffset === fileSize)
                {
                    done();
                }
            }
            else
            {
                throw new Error(`Read error: ${evt.target.error}`);
            }
        }

        const chunkReaderBlock = () =>
        {
            if (fileOffset < fileSize)
            {
                chunkSize = maxChunkSize;
                if (fileOffset + maxChunkSize > fileSize)
                {
                    chunkSize = maxChunkSize - ((fileOffset + maxChunkSize) - fileSize);
                }
                const r = new FileReader();

                const blob = file.slice(fileOffset, fileOffset + chunkSize);

                fileOffset += chunkSize;

                r.onload = readEventHandler;
                r.readAsArrayBuffer(blob);
            }
        }

        chunkReaderBlock();
    });
}
