import React, { useState } from 'react';
import {
  Button,
  FormHelperText,
  Grid,
  Slider,
  Typography
} from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';

const EditorImage = props => {
  const {
    canDelete,
    label,
    borderRadiusProp,
    rotateProp,
    scaleProp,
    // rotateProp,
    widthProp,
    heightProp,
    positionXProp,
    positionYProp,
    styleProp,
    editorRef,
    value,
    touched,
    errors
  } = props;

  const [borderRadius, setBorderRadius] = useState(borderRadiusProp);
  const [rotate, setRotate] = useState(rotateProp);
  const [scale, setScale] = useState(scaleProp);
  const [width, setWidth] = useState(widthProp);
  const [height, setHeight] = useState(heightProp);
  const [positionX, setPositionX] = useState(positionXProp);
  const [positionY, setPositionY] = useState(positionYProp);
  const [style/* , setstyle */] = useState(styleProp);
  const [origWidth, setOrigWidth] = useState(widthProp);
  const [origHeigth, setOrigHeight] = useState(heightProp);
  const [porcWidth, setPorcWidth] = useState(100);
  const [porcHeigth, setPorcHeigth] = useState(100);

  const [preview, setPreview] = useState('');

  const resetProperties = (file) => {
    setBorderRadius(0);
    setRotate(0);
    setScale(1);
    setPositionX(0.5);
    setPositionY(0.5);
    if (file) {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        setWidth(img.width < 400 ? img.width : 400);
        setHeight(img.height < 400 ? img.height : 400);
        setOrigHeight(img.height < 400 ? img.height : 400);
        setOrigWidth(img.width < 400 ? img.width : 400);
      };
    }
  };

  const handlePropChange = (newValue, handle) => {
    if (newValue) {
      handle(newValue);
      props.setFileTouched(true);
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      props.handleChange(file);
      resetProperties(file);
    }
    props.setFileTouched(false);
  };

  function handleFileDelete(event) {
    event.preventDefault();
    props.handleDelete();
    resetProperties();
  }

  const reglaDeTresSimple = (porc, origValue) => (porc * 100) / origValue;
  const elpmiSserTeDalger = (porc, origValue) => (porc * origValue) / 100;

  return (
    <div>
      <Grid container justify="center">
        <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2 ta-c">
          <Typography
            color="textPrimary"
            variant="overline"
            className="font-weight-bold"
          >
            {label}
          </Typography>
          <br />
          <AvatarEditor
            image={value}
            borderRadius={borderRadius}
            width={width}
            height={height}
            position={{ x: positionX, y: positionY }}
            scale={scale}
            rotate={rotate}
            ref={editorRef}
            crossOrigin="anonymous"
            style={style}
            disableBoundaryChecks
          />
          <br />
          <div style={{ margin: 'auto', width: '50%' }}>
            {
              canDelete && (
              <Button
                variant="contained"
                component="label"
                className="mb-3 mr-2"
                size="small"
                onClick={handleFileDelete}
              >
                Borrar Archivo
              </Button>
              )
            }
            <FormHelperText className="text-danger">{(touched && errors) && errors}</FormHelperText>
            <Button
              variant="contained"
              component="label"
              className="mb-3"
              size="small"
            >
              Subir Archivo
              <input
                type="file"
                hidden
                onBlur={props.handleBlur}
                onChange={e => handleFileChange(e)}
              />
            </Button>
            <br />
            <FormHelperText className="text-danger">
              {touched && errors && errors}
            </FormHelperText>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2 ta-c">
          <Typography
            color="textPrimary"
            variant="overline"
            className="font-weight-bold"
          >
            Previsualización
          </Typography>
          <br />
          {!!preview && (
            <img
              src={preview.img}
              alt="Img preview"
              style={{
                borderRadius: `${(Math.min(preview.height, preview.width) +
                  10) *
                  (preview.borderRadius / 2 / 100)}px`,
                backgroundColor: 'rgb(52, 186, 188)'
              }}
            />
          )}
          {/* <div className="mt-2" id="aux_image"  style={{backgroundImage: preview !== '' && dataURItoBlob(preview), borderRadius: borderRadius }} alt="" /> */}
        </Grid>
        <Grid item container xs={12} md={10}>
          <Grid xs={12} md={4} item className="pr-3 pl-3 pt-2 ta-c">
            Marco:
            <Slider
              name="borderRadius"
              value={borderRadius}
              onChange={(event, newValue) => handlePropChange(newValue, setBorderRadius)}
              aria-labelledby="continuous-slider"
              min={0}
              max={150}
              step={1}
            />
            <br />
            Rotar:
            <Slider
              name="rotate"
              value={rotate}
              onChange={(event, newValue) => handlePropChange(newValue, setRotate)}
              aria-labelledby="continuous-slider"
              min={-0.001}
              max={360}
              step={1}
            />
            <br />
            Zoom:
            <Slider
              name="scale"
              value={scale}
              onChange={(event, newValue) => handlePropChange(newValue, setScale)}
              aria-labelledby="continuous-slider"
              min={1}
              max={2}
              step={0.01}
            />
          </Grid>
          <Grid xs={12} md={4} container item className="pr-3 pl-3 pt-2 ta-c">
            <Grid xs={6} md={6} container item className="pr-3 pl-3 pt-2 ta-c">
              % Ancho:
              <input
                className="form-control"
                name="width"
                type="number"
                step="any"
                // pattern="[+-]?\d*(?:[.,]\d*)?"
                onChange={e => {
                  const newPorcWidth = parseFloat(e.target.value);
                  handlePropChange(newPorcWidth, setPorcWidth);
                  handlePropChange(elpmiSserTeDalger(newPorcWidth, origWidth), setWidth);
                }}
                value={porcWidth}
              />
              % Alto:
              <input
                className="form-control"
                name="height"
                type="number"
                step="any"
                // pattern="[+-]?\d*(?:[.,]\d*)?"
                onChange={e => {
                  const newPorcHeigth = parseFloat(e.target.value);
                  handlePropChange(newPorcHeigth, setPorcHeigth);
                  handlePropChange(elpmiSserTeDalger(newPorcHeigth, origHeigth), setHeight);
                }}
                value={porcHeigth}
              />
            </Grid>
            <br />
            <Grid xs={6} md={6} container item className="pr-3 pl-3 pt-2 ta-c">
              Ancho:
              <input
                className="form-control"
                name="width"
                type="number"
                step="any"
                onChange={(e) => {
                  const newNomWidth = parseFloat(e.target.value);
                  handlePropChange(newNomWidth, setWidth);
                  handlePropChange(reglaDeTresSimple(newNomWidth, origWidth), setPorcWidth);
                }}
                max="400"
                value={width}
              />
              Alto:
              <input
                className="form-control"
                name="height"
                type="number"
                step="any"
                onChange={(e) => {
                  const newNomHeigth = parseFloat(e.target.value);
                  handlePropChange(newNomHeigth, setHeight);
                  handlePropChange(reglaDeTresSimple(newNomHeigth, origHeigth), setPorcHeigth);
                }}
                max="400"
                value={height}
              />
            </Grid>
          </Grid>
          <Grid xs={12} md={4} item className="pr-3 pl-3 pt-2 ta-c">
            X Posición:
            <Slider
              name="scale"
              value={positionX}
              onChange={(event, newValue) => handlePropChange(newValue, setPositionX)}
              aria-labelledby="continuous-slider"
              min={0}
              max={1}
              step={0.01}
            />
            <br />
            Y Posición:
            <Slider
              name="scale"
              value={positionY}
              onChange={(event, newValue) => handlePropChange(newValue, setPositionY)}
              aria-labelledby="continuous-slider"
              min={0}
              max={1}
              step={0.01}
            />
            <Button
              className="ml-3"
              color="secondary"
              onClick={() => {
                const img = editorRef.current
                  .getImageScaledToCanvas()
                  .toDataURL();
                const rect = editorRef.current.getCroppingRect();
                setPreview({
                  img,
                  rect,
                  scale: scale,
                  width: width,
                  height: height,
                  borderRadius: borderRadius
                });
              }}
            >
              Previsualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

EditorImage.defaultProps = {
  borderRadiusProp: 0,
  rotateProp: 0,
  scaleProp: 1,
  widthProp: 250,
  heightProp: 250,
  positionXProp: 0.5,
  positionYProp: 0.5,
  label: 'Subida Logo'
};
export default EditorImage;
