/* eslint-disable array-bracket-spacing */
/* eslint-disable brace-style */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
import React from 'react';
import { SearchPanel } from 'src/components/shared/SearchPanel';
import { Box, Grid, TextField } from '@material-ui/core';
import { useQuery } from 'src/hooks/useQuery';
import * as Yup from 'yup';
import { DatatableContextProvider } from 'src/components/shared/datatable/DatatableContext';
import { DatatablePanel } from 'src/components/shared/datatable/DatatablePanel';
import { PrimaryLinkButton } from 'src/components/shared/Buttons/PrimaryLinkButton';
import { routesApp } from 'src/routesService';
import UploadService from 'src/services/UploadService';
import { DatatableActions } from 'src/components/shared/datatable/DatatableActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ArchivoDeleteDialog } from './ArchivoDeleteDialog';
import PageContainer from '../shared/PageContainer';

export function ArchivosContainerList()
{
    const title = 'Gestión de archivos';
    const query = useQuery();
    const history = useHistory();
    const [ uploadToDelete, setUploadToDelete ] = React.useState(null);

    return (
<>
<PageContainer title={title} maxWidth={false}>
<DatatableContextProvider fetch={(page, limit, filters) =>
{
    return UploadService.list(page, limit, filters);
}}>
    <Box
        display="flex"
        justifyContent="flex-end"
    >
        <PrimaryLinkButton to={routesApp.addFile.path}>
            + Subir archivo
        </PrimaryLinkButton>
    </Box>

    <SearchPanel
        initialValues={
        {
            title: query.get('title') ? query.get('title') : '',
            id: query.get('id') ? query.get('id') : '',
        }}
        validationSchema={Yup.object().shape(
        {
            title: Yup.string().max(128, 'El nombre no debe superar los 128 caracteres'),
            id: Yup.string(),
        })}
        onReset={(data, actions)=>
        {
            actions.setValues({
                id: '',
                title: '',
            });
            query.set('id_filter', '');
            query.set('title_filter', '');
            history.replace(`?${query.toString()}`);
        }}
        onSubmit={async (data) =>
        {
            const params = new URLSearchParams();

            if (data.id) params.set('id_filter', data.id);
            if (data.title) params.set('title_filter', data.title);

            history.replace(`?${params.toString()}`)
        }}
    >
        {({
            errors,
            handleBlur,
            handleChange,
            handleReset,
            isSubmitting,
            setFieldValue,
            touched,
            values,
        }) => (
        <>
            <Grid item xs={12} md={4}>
                <TextField
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    fullWidth
                    label="Título"
                    variant="outlined"
                    name="title"
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.id}
                    fullWidth
                    label="ID"
                    variant="outlined"
                    name="id"
                />
            </Grid>
        </>
        )}
    </SearchPanel>

    <Box mt={3}>

        <DatatablePanel
            title={title}
            columns={[
                {
                    id: 'id',
                    label: 'ID',
                    sortable: true,
                    render: (item) =>
                    {
                        return item.id;
                    }
                },
                {
                    id: 'title',
                    label: 'Título',
                    sortable: true,
                    render: (item) =>
                    {
                        return item.title;
                    }
                },
                {
                    id: 'url',
                    label: 'URL',
                    sortable: true,
                    render: (item) =>
                    {
                        return item.preview_url;
                    }
                },
                {
                    id: 'actions',
                    label: 'Acciones',
                    render: (item) =>
                    (
                        <DatatableActions items={[
                            {
                                id: 'edit',
                                label: 'Editar',
                                onClick: () =>
                                {
                                    history.push(routesApp.editFile.path.replace(':uploadId', item.id));
                                }
                            },
                            {
                                id: 'copy-url-preview',
                                label: 'Copiar URL Visualización',
                                onClick: () =>
                                {
                                    navigator.clipboard.writeText(item.preview_url);
                                }
                            },
                            {
                                id: 'copy-url-download',
                                label: 'Copiar URL Descarga',
                                onClick: () =>
                                {
                                    navigator.clipboard.writeText(item.download_url);
                                }
                            },
                            {
                                id: 'delete',
                                label: 'Eliminar',
                                onClick: () =>
                                {
                                    setUploadToDelete(item);
                                }
                            },
                        ]}
                        />
                    )
                }
            ]}
        />

    </Box>

    <ArchivoDeleteDialog
        upload={uploadToDelete}
        onClose={() =>
        {
            setUploadToDelete(null);
        }}
    />

</DatatableContextProvider>

</PageContainer>
</>
    )
}
