/* eslint-disable react/jsx-indent-props */
/* eslint-disable brace-style */
/* eslint-disable indent */
import React from 'react';
import { IconButton, InputAdornment, TextField, Tooltip, styled } from '@material-ui/core';
import { Copy } from 'react-feather';

const IconButtonWithTooltip = styled(IconButton)(
{
    root: {
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
});
const AlignedInputAdornment = styled(InputAdornment)(
{
    margin: '0 auto', // fix for vertically unaligned icon
});

export function CopiableTextField(props)
{
    const {
        name,
        label,
        value,
        disabled,
        readOnly,
        fullWidth,
        copyText,
        copyTooltip,
    } = props;

    const handleClick = () =>
    {
        if (copyText && value)
        {
            copyText(value);
        }
    }

    return (
<TextField
    name={name}
    label={label}
    value={value}
    disabled={disabled}
    variant="filled"
    fullWidth={fullWidth}
    InputProps={{
        readOnly,
        endAdornment: (
            <AlignedInputAdornment position="end">
            {disabled ? (
                <IconButtonWithTooltip disabled={false} onClick={handleClick}>
                    <Copy />
                </IconButtonWithTooltip>
            ) : (
                <Tooltip title={copyTooltip}>
                <IconButtonWithTooltip role="button" disabled={disabled} onClick={handleClick}>
                    <Copy />
                </IconButtonWithTooltip>
                </Tooltip>
            )}
            </AlignedInputAdornment>
        ),
    }}
/>
    )
}
