/* eslint-disable brace-style */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableHeader from 'src/components/shared/TableHeader';
import { useDatatable } from './DatatableContext';

export function DatatablePanel({ title, columns })
{
    const {
        loading,
        items,
        totalItems,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
    } = useDatatable();

    return (
<>
<Card>
    <PerfectScrollbar>
        <Box minWidth={1050}>
            <Typography variant="subtitle1" className="mt-3 ml-3 mb-2">
                {title}
                {loading && (
                <CircularProgress size={24} style={{ marginLeft: 15 }} />
                )}
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        { columns.map((c) =>
                        {
                            if (c.sortable)
                            {
                                return (
                                <TableHeader key={c.id} property={c.id}>
                                    {c.label}
                                </TableHeader>
                                )
                            }
                            return <TableCell key={c.id}>{c.label}</TableCell>
                        }) }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <TableRow
                            key={item.id}
                            hover
                        >
                            { columns.map((c) => (
                                <TableCell key={c.id} property={c.id}>
                                    {c.render(item)}
                                </TableCell>
                            )) }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    </PerfectScrollbar>

    {items.length === 0 && (
        <div className="text-center mt-2">
          No hay resultados para su búsqueda
        </div>
    )}
    <TablePagination
        component="div"
        className="paginador"
        count={totalItems}
        onPageChange={(event, page) =>
        {
            setCurrentPage(page + 1);
        }}
        onRowsPerPageChange={(event) =>
        {
            setPageSize(event.target.value);
        }}
        page={currentPage - 1}
        rowsPerPage={parseInt(pageSize, 10)}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
    />

</Card>
</>
    )
}
