import React from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  makeStyles,
  Grid,
  FormControl
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router';
import { useQuery } from 'src/hooks/useQuery';
import { getDataFormatedForSelects, getDataSelected } from 'src/helpers/random';
import SelectField from '../../shared/SelectField';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const BarraBusqueda = ({ className, roles, ...rest }) => {
  const classes = useStyles();
  const query=useQuery()
  const history=useHistory()

  const getRolesOptions = () => {
    return getDataFormatedForSelects(roles, 'id', 'display_name');
  };

  return (
    <Box mt={3}>
      <Card color="#eeeeee">
        <CardContent>
          <Formik
            initialValues={{
              name: query.get('name_filter') ? query.get('name_filter') : '',
              lastname: query.get('lastname_filter') ? query.get('lastname_filter') : '',
              username: query.get('username_filter') ? query.get('username_filter') : '',
              rol: query.get('roles_id_filter') ? getDataSelected(roles, 'id', query.get('roles_id_filter'), 'display_name') : ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255, 'El nombre no debe superar los 255 caracteres'),
              lastname: Yup.string().max(255, 'El apellido no debe superar los 255 caracteres'),
              username: Yup.string().max(255, 'El nombre de usuario no debe superar los 255 caracteres'),
              rol:Yup.string().nullable()
            })}
            onSubmit={async (data) => {
              query.set('name_filter', data.name)
              query.set('lastname_filter', data.lastname)
              query.set('username_filter', data.username)
              query.set('roles_id_filter', data.rol ? data.rol.value : "")
              history.push(`?${query.toString()}`)
            }}
            onReset={(data, actions)=>{
              actions.setValues({
                name:'',
                username:'',
                lastname:'',
                rol:''
              })
              query.set('name_filter', "")
              query.set('lastname_filter', "")
              query.set('username_filter', "")
              query.set('roles_id_filter', "")
              history.push(`?${query.toString()}`)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleReset,
              isSubmitting,
              touched,
              setFieldValue,
              values
            }) => (
                <Form>
                  <Grid container>
                    <Grid xs={12} md={3} className="p-2" item>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        fullWidth
                        label="Nombre"
                        variant="outlined"
                        name="name"
                      />
                    </Grid>
                    <Grid item xs={12} md={3} className="p-2">
                      <TextField
                        error={Boolean(touched.lastname && errors.lastname)}
                        helperText={touched.lastname && errors.lastname}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastname}
                        fullWidth
                        label="Apellido"
                        variant="outlined"
                        name="lastname"
                      />
                    </Grid>
                    <Grid item xs={12} md={3} className="p-2">
                      <TextField
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.lastname && errors.username}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.username}
                        fullWidth
                        label="Usuario"
                        variant="outlined"
                        name="username"
                      />
                    </Grid>
                    <Grid item xs={12} md={3} className="p-2">
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <SelectField
                          placeholder="Seleccione un rol..."
                          name="rol"
                          getOptions={getRolesOptions}
                          label="Rol"
                          setFieldValue={(value) => {
                            setFieldValue('rol', value);
                          }}
                          value={values.rol}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="pt-2 pl-2 text-right">
                      <Button
                        className={classes.importButton}
                        onClick={handleReset}
                      >
                        Limpiar
                        </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                        type={"submit"}
                      >
                        Buscar
                        </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BarraBusqueda;
