import React from "react";

export function ItemBtn({ variant, children, disabled, onClick })
{
  const ref = React.useRef(null);

  return (
<button
  ref={ref}
  className={`item-btn item-btn-${variant}`}
  disabled={disabled}
  onClick={(e) =>
  {
    e.preventDefault();
    e.stopPropagation();
    onClick();
    ref.current.disabled = true;
    setTimeout(() =>
    {
      if(ref.current)
        ref.current.disabled = false;
    }, [])
  }}
>
  { children }
</button>
  )
}
