/* eslint-disable react/jsx-indent-props */
/* eslint-disable brace-style */
/* eslint-disable indent */
import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import ErrorsValidation from 'src/components/shared/displayErrorsValidations';
import { ArchivoEditor } from './ArchivoEditor';
import PageContainer from '../shared/PageContainer';

export function ArchivosContainerAdd()
{
    return (
<>
<PageContainer title="Agregar archivo" maxWidth={false}>
    <Typography
        color="textPrimary"
        variant="h2"
        className="mb-3"
    >
        Agregar archivo
    </Typography>
    <ErrorsValidation errors={undefined} />
    <Card>
        <CardContent>
            <ArchivoEditor />
        </CardContent>
    </Card>
</PageContainer>
</>
    )
}
