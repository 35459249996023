import React from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  makeStyles,
  Grid,
  FormControl
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router';
import { useQuery } from 'src/hooks/useQuery';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/es";
import { getDataFormatedForSelects, getDataSelected } from 'src/helpers/random';
import SelectField from '../../shared/SelectField';

moment.locale('es');

const useStyles = makeStyles((theme) => ({
  root: {
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const BarraBusqueda = ({ className, states, ...rest }) => {
  const classes = useStyles();
  const query = useQuery()
  const history = useHistory()

  const getEstadoOptions = () => {
    return getDataFormatedForSelects(states, 'id', 'display_name');
  };

  return (
    <Box mt={3}>
      <Card color="#eeeeee">
        <CardContent>
          <Formik
            initialValues={{
              title: query.get('title_filter') ? query.get('title_filter') : '',
              state: query.get('state_id_filter') ? getDataSelected(states, 'id', query.get('state_id_filter'), 'display_name') : '',
              postDate: query.get('post_date_filter') ? query.get('post_date_filter') : null,
              updateDate: query.get('updated_at_filter') ? query.get('updated_at_filter') : '',
              updateByUser: query.get('updatedBy_username_filter') ? query.get('updatedBy_username_filter') : ''
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().max(255, 'El título no debe superar los 255 caracteres'),
              state: Yup.string().nullable(),
              postDate: Yup.string().max(255, 'El postDate no debe superar los 255 caracteres').nullable(),
              updateDate: Yup.string().max(255, 'El updateDate no debe superar los 255 caracteres'),
              updateByUser: Yup.string().max(255, 'El updateByUser no debe superar los 255 caracteres'),
            })}
            onSubmit={async (data) => {
              query.set('title_filter', data.title)
              query.set('state_id_filter', data.state ? data.state.value : "")
              query.set('post_date_filter', data.postDate ? data.postDate.format('YYYY-MM-DD') : '')
              query.set('updatedBy_username_filter', data.updateByUser)
              history.push(`?${query.toString()}`)
            }}
            onReset={(data, actions) => {
              actions.setValues({
                title: '',
                state: '',
                postDate: null,
                updateDate: '',
                updateByUser: '',
              })
              query.set('title_filter', "")
              query.set('state_id_filter', "")
              query.set('post_date_filter', "")
              query.set('updatedBy_username_filter', "")
              history.push(`?${query.toString()}`)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleReset,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (
                <Form>
                  <Grid container>
                    <Grid xs={12} md={3} className="p-2" item>
                      <TextField
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.title}
                        fullWidth
                        label="Título"
                        variant="outlined"
                        name="title"
                      />
                    </Grid>
                    <Grid item xs={12} md={3} className="p-2">
                      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'es'}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="DD/MM/yyyy"
                          id="date-picker-post-date"
                          label="Fecha publicación"
                          value={values.postDate}
                          onChange={(date) => setFieldValue('postDate', date)}
                          KeyboardButtonProps={{
                            'aria-label': 'Cambiar fecha',
                          }}
                          fullWidth
                          inputVariant="outlined"
                          onBlur={handleBlur}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={3} className="p-2">
                      <TextField
                        error={Boolean(touched.updateByUser && errors.updateByUser)}
                        helperText={touched.updateByUser && errors.updateByUser}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.updateByUser}
                        fullWidth
                        label="Usuario Ult. Modificación"
                        variant="outlined"
                        name="updateByUser"
                      />
                    </Grid>
                    <Grid item xs={12} md={3} className="p-2">
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <SelectField
                          placeholder="Seleccione un estado..."
                          name="state"
                          getOptions={getEstadoOptions}
                          label="Estado"
                          setFieldValue={(value) => {
                            setFieldValue('state', value);
                          }}
                          value={values.state}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="pt-2 pl-2 text-right">
                      <Button
                        className={classes.importButton}
                        onClick={handleReset}
                      >
                        Limpiar
                        </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                        type={"submit"}
                      >
                        Buscar
                        </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BarraBusqueda;
