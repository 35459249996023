import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Loader from "react-loader-spinner";
import CKEditor from 'ckeditor4-react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography } from '@material-ui/core';
// import { EcoTwoTone } from '@material-ui/icons';

import configCKEditor from 'src/config/ckeditor';
import { showDeveloperMessageConsole } from 'src/helpers/random';
// import api from 'src/api';

const Resumable = require('resumablejs');

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1rem',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 10110,
  },
}));

const CkeditorJuanaManso = (props) => {
  const {
    configParent,
    id,
    value,
    styles,
    name,
    nameLength
  } = props;

  const classes = useStyles();
  const [uploading, setUploading] = useState(false);

  const setStylesAndLength = (editor) => {
    if (styles) {
      styles.forEach((style) => {
        editor.document.getBody().setStyle(style.name, style.value);
      });
    }

    if (nameLength) {
      // const aux = editor.plugins.get('WordCount');
      const rawText = editor.editable().getText();
      props.setFieldValue(nameLength, rawText.length);
    }
  };

  const setFields = (setField, setFieldLength) => {
    // showDeveloperMessageConsole(Array.from(editor.ui.componentFactory.names()))
    setField(name);
    if (nameLength) {
      setFieldLength(nameLength);
    }
  };

  const setValues = (editor) => {
    setFields(
      (val) => props.setFieldValue(val, editor.getData()),
      (val) => props.setFieldValue(val, editor.editable().getText().length)
    );
  };

  const setTouched = () => {
    setFields(props.setFieldTouched, props.setFieldTouched);
  };


  return (
    <div>
      { uploading && (
        <Modal
          open={uploading}
          style={{ zIndex: 10110 }}
          aria-labelledby="modal-modal-title"
        >
          <div
            style={{
              top: `50%`,
              left: `50%`,
              transform: `translate(-50%, -50%)`,
            }}
            className={classes.paper}
          >
            <Typography id="modal-modal-title" className='text-center' component="h2">
              Subiendo Archivo
            </Typography>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'auto',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            >
              <Loader type="Oval" color="#ccc" height={120} width={120} />
            </div>
          </div>
        </Modal>
      )}
      <ul className="resumable-list" style={{ display: 'none' }}></ul>
      <CKEditor
        id={id}
        data={value}
        config={{ ...configCKEditor, ...configParent }}
        name="ckeditor_backoffice"
        onNamespaceLoaded={(CKEDITOR) => {
          // If namespace was already loaded, this callback will be never called!
          // There will be only one attempt to add external plugin.
          // showDeveloperMessageConsole(CKEDITOR);
          CKEDITOR.plugins.addExternal(
            'simage',
            '/ckeditor/ckeditor4-simage/',
            'plugin.js'
          );

          CKEDITOR.plugins.addExternal(
            'widget',
            '/ckeditor/widget/',
            'plugin.js'
          );
/*
          CKEDITOR.plugins.addExternal(
            'html5audio',
            '/ckeditor/html5audio/',
            'plugin.js'
          );

          CKEDITOR.plugins.addExternal(
            'html5video',
            '/ckeditor/html5video/',
            'plugin.js'
          );
 */
          // eslint-disable-next-line dot-notation
          CKEDITOR.dtd['a']['div'] = 1;
          // eslint-disable-next-line dot-notation
          CKEDITOR.dtd['a']['p'] = 1;
          // eslint-disable-next-line dot-notation
          CKEDITOR.dtd['a']['i'] = 1;
          // eslint-disable-next-line dot-notation
          CKEDITOR.dtd['a']['span'] = 1;

          CKEDITOR.on('instanceCreated', function(event) {
            const editor = event.editor;
            var _xhr;

            editor.on('fileUploadResponse', function(evt) {
              // Prevent the default response handler.

              if(Object.keys(evt.data).length === 0)
                evt.data = _xhr;

              setUploading(false);
              // evt.stop();
            });

            editor.on('fileUploadRequest', function(evt) {
              setUploading(true);
              const requestData = evt.data.requestData;
              const xhr = evt.data.fileLoader.xhr;
              xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('access_token')}`);

              if(requestData.upload) {
                const resumable = new Resumable({
                  target: `${process.env.REACT_APP_BASE_URL}upload-media`,
                  simultaneousUploads: false,
                  chunkSize: process.env.REACT_APP_FILE_UPLOAD_CHUNK_SIZE * 1024 * 1024, //bytes
                  testChunks:false,
                  prioritizeFirstAndLastChunk: true,
                  forceChunkSize: true,
                  headers: {
                    "Authorization" : 'Bearer ' + localStorage.getItem('access_token'),
                  }
                });

                const uploadFile = requestData.upload.file;
                const formData = new FormData();

                resumable.on('fileAdded', function(resumableFile, event) {
                  // Add the file to the list
                  document.querySelector('.resumable-list').append('<li className="resumable-file-'+resumableFile.uniqueIdentifier+'">Uploading <span className="resumable-file-name"></span> <span class="resumable-file-progress"></span>');
                  // document.querySelector('.resumable-file-'+file.uniqueIdentifier+' .resumable-file-name').innerHtml = file.fileName;
                  if(resumableFile.chunks.length > 1) {
                    const finishedChunks = [];
                    const lastChunk = resumableFile.chunks.pop();
                    resumableFile.chunks.forEach((chunk) => {
                      const __xhr = new XMLHttpRequest();
                      __xhr.addEventListener('load', function () {
                        finishedChunks.push({ [chunk.offset]: 'OK' });

                        if(finishedChunks.length === (lastChunk.offset)) {
                          formData.append('resumableChunkNumber', lastChunk.offset + 1);
                          formData.append('resumableChunkSize', lastChunk.resumableObj.getOpt('chunkSize'));
                          formData.append('resumableCurrentChunkSize', lastChunk.endByte - lastChunk.startByte);
                          formData.append('resumableTotalSize', resumableFile.size);
                          formData.append('resumableType', lastChunk.fileObjType);
                          formData.append('resumableIdentifier', lastChunk.fileObj.file.uniqueIdentifier);
                          formData.append('resumableFilename', resumableFile.fileName);
                          formData.append('resumableRelativePath', resumableFile.fileName);
                          formData.append('resumableTotalChunks', resumableFile.chunks.length + 1);
                          formData.append('file', new File([lastChunk.fileObj.file.slice(lastChunk.startByte, lastChunk.endByte, resumableFile.file.type)], resumableFile.fileName), resumableFile.fileName);

                          _xhr = xhr;
                          _xhr.send(formData);
                        }
                      });

                      const __formData  = new FormData();
                      __formData.append('resumableChunkNumber', chunk.offset + 1);
                      __formData.append('resumableChunkSize', chunk.resumableObj.getOpt('chunkSize'));
                      __formData.append('resumableCurrentChunkSize', chunk.endByte - chunk.startByte);
                      __formData.append('resumableTotalSize', resumableFile.size);
                      __formData.append('resumableType', chunk.fileObjType);
                      __formData.append('resumableIdentifier', chunk.fileObj.file.uniqueIdentifier);
                      __formData.append('resumableFilename', resumableFile.fileName);
                      __formData.append('resumableRelativePath', resumableFile.fileName);
                      __formData.append('resumableTotalChunks', resumableFile.chunks.length + 1);
                      __formData.append('file', new File([resumableFile.file.slice(chunk.startByte, chunk.endByte, resumableFile.file.type)], resumableFile.fileName), resumableFile.fileName);

                      __xhr.open( 'POST', resumable.opts.target, true);
                      __xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
                      __xhr.send(__formData);
                    });
                  } else {
                    const __formData  = new FormData();
                    __formData.append('file', resumableFile.file, resumableFile.file.name);
                    xhr.send(__formData);
                  }
                });

                resumable.addFile(uploadFile);

                // Prevented the default behavior.
                evt.stop();
              } else {
                xhr.setRequestHeader('Cache-Control', 'no-cache');
              }
            });
          });

          /*
          const baseUrl = process.env.REACT_APP_BASE_URL:
          const token = localStorage.get('access_token'):
          CKEDITOR.replaceAll({
            extraPlugins: 'simage',
            removePlugins: 'image',
            imageUploadURL: `${baseUrl}upload?type=Files&api_token=${token}`,
            dataParser: (data) => data.url
          });
          */
        }}
        onInstanceReady={(e) => {
          // showDeveloperMessageConsole('Editor is ready to use!', e.editor);
          setStylesAndLength(e.editor);
        }}
        onChange={(e) => setValues(e.editor)}
        onBlur={() => setTouched()}
        onError={(e) => showDeveloperMessageConsole('Error: ', e)}
      />
    </div>
  );
};

CkeditorJuanaManso.propTypes = {
  styles: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};

export default CkeditorJuanaManso;
