/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Button } from '@material-ui/core';

const UploadFileButton = ({ disabled, handleBlur, handleFileChange, fullWidth, label, accept }) => (
    <Button
        size="small"
        variant="contained"
        component="label"
        className="mb-3"
        disabled={disabled}
        fullWidth={fullWidth}
    >
        { label || 'Subir Archivo' }
        <input
            type="file"
            hidden
            disabled={disabled}
            onBlur={handleBlur}
            onChange={(e) =>
            {
              handleFileChange(e.target.files.length === null ? null : e.target.files[0]);
            }}
            accept={accept}
        />
    </Button>
);
export default UploadFileButton;
