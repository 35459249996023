import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ConfirmDialog from './ConfirmDialog';

const DeleteItem = ({
  deleteAction, item, title, message
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  return (
    <div>
      <MenuItem onClick={() => setConfirmOpen(true)}>
        Eliminar
      </MenuItem>
      <ConfirmDialog
        title={title}
        open={confirmOpen}
        onClose={() =>
        {
          setConfirmOpen(false);
        }}
        onConfirm={() => deleteAction(item)}
      >
        {message}
      </ConfirmDialog>
    </div>
  );
};

export default DeleteItem;
