/* eslint-disable react/jsx-indent-props */
/* eslint-disable brace-style */
/* eslint-disable indent */
import React from 'react'
import { NotificationManager } from 'react-notifications';
import ConfirmDialog from 'src/components/shared/ConfirmDialog';
import { useDatatable } from 'src/components/shared/datatable/DatatableContext';
import UploadService from 'src/services/UploadService';

export function ArchivoDeleteDialog({ upload, onClose })
{
    const { refresh } = useDatatable();

    return !upload
    ?
<></>
    : (
<ConfirmDialog
    title="Eliminar archivo"
    open={true}
    onClose={onClose}
    onConfirm={() =>
    {
        UploadService.delete(upload.id)
        .then(() =>
        {
            refresh();
            NotificationManager.success('Archivo eliminado exitosamente');
        })
        .catch(() =>
        {
            NotificationManager.error('Error al eliminar el archivo.');
        });
    }}
>
    { `¿Deseas eliminar el archivo "${upload.title}"?` }
</ConfirmDialog>
    )
}
