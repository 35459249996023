//Constantes de estados y demas de la base de datos

export const STATES_PAGINAS_INSTITUCIONALES={
  BORRADOR: 1,
  PUBLICADO: 2,
  DESPUBLICADO: 3
}

export const NAMES_PAGINAS_INSTITUCIONALES_STATES = {
  BORRADOR: 'BORRADOR',
  PUBLICADO: 'PUBLICADO',
  DESPUBLICADO: 'DESPUBLICADO'
}

export const PORTADAS_STATES={
  BORRADOR: 1,
  PUBLICADO: 2,
}

export const COMPONENTS_TYPES={
  DESTACADO_PRINCIPAL: 1,
  DESTACADO_IMAGEN_CENTRO: 2,
  DESTACADO_IMAGEN_IZQUIERDA: 3,
  TITULO: 4,
  TEXTO: 5,
  BUSCADOR_RECURSOS: 6,
  EMBEBIDO: 7,
  NOTICIA: 8,
  AGRUPADOR: 9,
  BOTON: 10,
}

export const FILAS_OPTIONS = [
  {
    fila: 1,
    columnas: [
      1,
      2
    ]
  },
  {
    fila: 2,
    columnas: [
      2,
      4
    ]
  },
  {
    fila: 3,
    columnas: [
      1,
      2,
      3,
      4
    ]
  },
  {
    fila: 4,
    columnas: [
      1
    ]
  }
];

export const TAMANIOS_COMPONENTES_OPTIONS = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2
  },
  {
    value: 3,
    label: 3
  },
  {
    value: 4,
    label: 4
  }
];
