import React from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  useHistory,
} from 'react-router-dom';
import { useQuery } from 'src/hooks/useQuery';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const BarraBusquedaAgrupadores = ({ className, ...props }) => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();

  return (
    <Box mt={3}>
      <Card color="#eeeeee">
        <CardContent>
          <Formik
            initialValues={{
              question: query.get('question_filter') ? query.get('question_filter') : '',
              answer: query.get('answer_filter') ? query.get('answer_filter') : '',
              order: query.get('order_filter') ? query.get('order_filter') : "",
            }}
            validationSchema={Yup.object().shape({
              question: Yup.string(),
              answer: Yup.string(),
              order: Yup.number(),
            })}
            onSubmit={async (data) => {
              query.set('question_filter', data.question);
              query.set('answer_filter', data.answer);
              query.set('order_filter', data.order);
              history.push(`?${query.toString()}`);
            }}
            onReset={(data, actions) => {
              actions.setValues({
                question: "",
                answer:"",
                order:""
              });
              query.set('question_filter', "");
              query.set('answer_filter', "");
              query.set('order_filter', "");
              history.push(`?${query.toString()}`);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleReset,
              isSubmitting,
              touched,
              values
            }) => (
              <Form className={classes.root} noValidate autoComplete="off">
                <Grid container>
                  <Grid xs={12} md={4} className="p-2" item> {/* 12 4 */}
                    <TextField
                      error={Boolean(touched.question && errors.question)}
                      helperText={touched.question && errors.question}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.question}
                      fullWidth
                      label="Pregunta"
                      name="question"
                      variant="outlined"
                      type="text"
                    />
                  </Grid>
                  <Grid xs={12} md={4} className="p-2" item>
                    <TextField
                      error={Boolean(touched.answer && errors.answer)}
                      helperText={touched.answer && errors.answer}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.answer}
                      fullWidth
                      label="Respuesta"
                      name="answer"
                      variant="outlined"
                      type="text"
                    />
                  </Grid>
                  <Grid xs={12} md={4} className="p-2" item> {/* 12 4 */}
                    <TextField
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.order}
                      fullWidth
                      label="Orden"
                      name="order"
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} className="pt-2 pl-2 text-right">
                    <Button
                      className={classes.importButton}
                      onClick={handleReset}
                      type="button"
                    >
                      Limpiar
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BarraBusquedaAgrupadores;
