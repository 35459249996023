/* eslint-disable react/jsx-indent-props */
/* eslint-disable brace-style */
/* eslint-disable indent */

import React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    makeStyles,
    Grid,
} from '@material-ui/core';
import { Form, Formik } from 'formik';

const useStyles = makeStyles((theme) => (
{
    root: {
    },
    importButton: {
        marginRight: theme.spacing(1),
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

export function SearchPanel(props)
{
    const {
        children,
        initialValues,
        validationSchema,
        onSubmit,
        onReset,
    } = props;

    const classes = useStyles();

    return (
<>
<Box mt={3}>
    <Card color="#eeeeee">
        <CardContent>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                onReset={onReset}
            >
            {({
                errors,
                handleBlur,
                handleChange,
                handleReset,
                isSubmitting,
                touched,
                setFieldValue,
                values,
            }) => (
                <Form>
                    <Grid container spacing={2}>
                        { children({
                            errors,
                            handleBlur,
                            handleChange,
                            handleReset,
                            isSubmitting,
                            touched,
                            setFieldValue,
                            values,
                        }) }
                    </Grid>

                    <div className="pt-2 pl-2 text-right">
                        <Button
                            className={classes.importButton}
                            onClick={handleReset}
                        >
                            Limpiar
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                        >
                            Buscar
                        </Button>
                    </div>
                </Form>
              )}
            </Formik>
        </CardContent>
    </Card>
</Box>
</>
    )
}
