/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable brace-style */
/* eslint-disable react/react-in-jsx-scope */
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48;

export function DatatableActions({ items })
{
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () =>
    {
        setAnchorEl(null);
    };

    const handleClick = (event) =>
    {
        setAnchorEl(event.currentTarget);
    };

  return (
<div>

    <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
    >
        <MoreVertIcon />
    </IconButton>

    <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl != null}
        onClose={handleClose}
        PaperProps={{
            style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                minWidth: '216px',
            },
        }}
    >
        { (items || []).map((item) =>
        {
            return (
            <MenuItem key={item.id} onClick={item.onClick}>
                { item.label }
            </MenuItem>
            )
        }) }
    </Menu>

</div>
    )
}
