/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable brace-style */
/* eslint-disable indent */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const DatatableContext = React.createContext({});

export const DatatableContextProvider = ({ children, fetch } ) =>
{
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [totalItems, setTotalItems] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(5);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [sortedColumns, setSortedColumns] = React.useState({});
    const location = useLocation();

    const callFetch = () =>
    {
        setLoading(true);
        const { search } = location;
        const filters = `${search !== '' ? search.substring(1) : search}`;

        fetch(currentPage, pageSize, filters).then((res) =>
        {
            setItems(res.data.data);
            setTotalItems(res.data.total);

            if (res.data.data.length === 0 && currentPage > 1)
            {
                setCurrentPage(Math.ceil(res.data.total / pageSize));
            }
        })
        .catch((error) =>
        {
            throw error;
        })
        .finally(() =>
        {
            setLoading(false);
        });
    }

    React.useEffect(() =>
    {
        callFetch();
    }, [pageSize, currentPage, sortedColumns, location.search]);

    return (
    <DatatableContext.Provider value={{
        loading,
        refresh: callFetch,
        items,
        setItems,
        totalItems,
        setTotalItems,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,

        sortedColumns,
        setSortedColumns,
    }}>
        { children }
    </DatatableContext.Provider>
    )
}

export const useDatatable = () =>
{
    return useContext(DatatableContext);
}
